import requestWithLoading from '@/utils/requestWithLoading';
import request from '@/utils/request';
export function wxConfig(url) {
  return requestWithLoading({
    url: 'wx/sign/url',
    method: 'get',
    params: {
      url: url
    }
  });
}
export function paySuccess(data) {
  return requestWithLoading({
    url: '/wx/charge/payResult',
    method: 'post',
    data: data
  });
}
export function askPayResult(yqqNo) {
  return request({
    url: "/wx/charge/payResult/".concat(yqqNo),
    method: 'GET'
  });
}
// 批量充值订单查询
export function batchPayResult(outTradeNo) {
  return request({
    url: "/wx/order/batch/record/".concat(outTradeNo),
    method: 'GET'
  });
}

// 再次支付，充值记录页面，调用此接口
export function orderPayAgain(data, outTradeNo) {
  return requestWithLoading({
    url: "/wx/charge/again/pay?outTradeNo=".concat(outTradeNo),
    method: 'post',
    data: data
  });
}